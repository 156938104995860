import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Container, Row, Col } from "react-bootstrap";
import {
  meta,
  worktimeline,
  projects,
  datawork,
  allSkills,
} from "helper/constants/content_option";
import Table from "components/Section/table";
import Grids from "components/Section/grid";
import ListSection from "components/Section/listSection";

export const Work = () => {
  return (
    <HelmetProvider>
      <Container>
        <Helmet>
          <meta charSet="utf-8" />
          <title> Career | {meta.title}</title>
          <meta name="description" content={meta.description} />
        </Helmet>
        <Row className="mb-5 mt-3 pt-md-3">
          <Col lg="8">
            <h1 className="display-4 mb-4">Career</h1>
            <hr className="t_border my-4 ml-0 text-left" />
          </Col>
        </Row>
        <Row className="sec_sp">
          <Col lg="5">
            <h3 className="color_sec py-4">{datawork.title}</h3>
          </Col>
          <Col lg="7" className="d-flex align-items-center">
            <div>
              <p>{datawork.aboutme}</p>
            </div>
          </Col>
        </Row>
        <Row className=" sec_sp">
          <Col lg="5">
            <h3 className="color_sec py-4">Work Timeline</h3>
          </Col>
          <Col lg="7">
            <Table list={worktimeline} />
          </Col>
        </Row>
        <Row className=" sec_sp">
          <Col lg="5">
            <h3 className="color_sec py-4">Work Responsibilities</h3>
          </Col>
          <Col lg="7">
            <ListSection list={worktimeline} />
          </Col>
        </Row>
        <Row className="sec_sp">
          <Col lg="5">
            <h3 className="color_sec py-4">Technical Languages</h3>
          </Col>
          <Col lg="7">
            <Grids number={4} list={allSkills?.technicalLanguages} />
          </Col>
        </Row>
        <Row className="sec_sp">
          <Col lg="5">
            <h3 className="color_sec py-4">Technical Skills</h3>
          </Col>
          <Col lg="7">
            <Grids number={4} list={allSkills?.technicalSkills} />
          </Col>
        </Row>
        <Row className="sec_sp">
          <Col lg="5">
            <h3 className="color_sec py-4">Software</h3>
          </Col>
          <Col lg="7">
            <Grids number={4} list={allSkills?.software} />
          </Col>
        </Row>{" "}
        <Row className="sec_sp">
          <Col lang="5">
            <h3 className="color_sec py-4">Projects</h3>
          </Col>
          <Col lg="7">
            <ListSection list={projects} />
          </Col>
        </Row>
      </Container>
    </HelmetProvider>
  );
};
