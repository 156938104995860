import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Container, Row, Col } from "react-bootstrap";
import { dataportfolio, meta } from "helper/constants/content_option";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

export const Portfolio = () => {
  return (
    <HelmetProvider>
      <Container>
        <Helmet>
          <meta charSet="utf-8" />
          <title> Snaps | {meta.title} </title>{" "}
          <meta name="description" content={meta.description} />
        </Helmet>
        <Row className="mb-5 mt-3 pt-md-3">
          <Col lg="8">
            <h1 className="display-4 mb-4">Snaps</h1>{" "}
            <hr className="t_border my-4 ml-0 text-left" />
          </Col>
        </Row>
        {/* <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
          <Masonry> */}
        <div
          style={{ display: "flex", alignItems: "center" }}
          className="flex flex-row items-center justify-center"
        >
          <div className="flex flex-col items-center justify-center">
            {dataportfolio?.slice(0, 4)?.map((item) => {
              return <img src={item} alt="" className="p-2 max-w-96" />;
            })}
          </div>
          <div className="flex flex-col items-center justify-center">
            {dataportfolio?.slice(4, 8)?.map((item) => {
              return <img src={item} alt="" className="p-2 max-w-96" />;
            })}
          </div>
          <div className="flex flex-col items-center justify-center">
            {dataportfolio?.slice(8, 12)?.map((item) => {
              return <img src={item} alt="" className="p-2 max-w-96" />;
            })}
          </div>
        </div>
        {/* </Masonry>
        </ResponsiveMasonry> */}
      </Container>
    </HelmetProvider>
  );
};
