import React from "react";
import { Route, Routes } from "react-router-dom";
import { Home } from "pages/Home";
import { Portfolio } from "pages/Portfolio";
import { ContactUs } from "pages/Contact";
import { About } from "pages/About";
import { Academics } from "pages/Academics";
import { Work } from "pages/Work";
import { Socialicons } from "components/SocialIcons";
import { Resources } from "pages/Resources";
import { Article } from "pages/Resources/Article";

function AppRoutes() {
  return (
    <div className="s_c">
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/academics" element={<Academics />} />
        <Route path="/work" element={<Work />} />
        <Route path="/about" element={<About />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/resources" element={<Resources />} />
        <Route path="/article/:id" element={<Article />} />
        <Route path="*" element={<Home />} />
      </Routes>
      <Socialicons />
    </div>
  );
}

export default AppRoutes;
