import React from "react";

function Table({ list }) {
    return (
        <>
            <table className="table bg-transparent caption-top">
                <tbody>
                    {list.map((data, i) => {
                        return (
                            <tr key={i}>
                                <th scope="row">{data.name}</th>
                                <td>{data.where}</td>
                                <td>{data.when}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </>
    );
}

export default Table;
