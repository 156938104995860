import React from "react";
import { useNavigate } from "react-router-dom";

function ListSection({ list }) {
  const navigate = useNavigate();
  return (
    <>
      {list.map((data, i) => {
        return (
          <div className="service_ py-4" key={i}>
            <div
              className={`service__title ${data?.description && data?.description !== null ? "border-title" : ""}`}
            >
              <div
                onClick={() => {
                  if (data?.link) {
                    window.open(data?.link, "__blank");
                  } else if (data?.path) {
                    navigate(data?.path);
                  }
                }}
                className={`flex flex-col space-y-1 ${data?.link || data?.path ? "cursor-pointer hover:underline underline-offset-2" : ""}`}
              >
                <h5 className="m-0">{data.name || data?.title}</h5>
                {data?.where && (
                  <h6 className="italic text-sm">{data.where}</h6>
                )}
              </div>
              <h6 className="m-0">{data.when}</h6>
            </div>
            <p className="service_desc">{data.description}</p>
          </div>
        );
      })}
    </>
  );
}

export default ListSection;
