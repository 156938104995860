import React, { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Container, Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { articles } from "helper/constants/articles";
import { meta } from "helper/constants/content_option";
import ReactMarkdown from "react-markdown";
export const Article = () => {
  const { id } = useParams();
  const [data, setData] = useState();

  useEffect(() => {
    setData(articles?.find((a) => a?.id == id));
  }, []);

  return (
    <HelmetProvider>
      <Container>
        <Helmet>
          <meta charSet="utf-8" />
          <title> Article | {data?.title || meta?.title} </title>{" "}
          <meta
            name="description"
            content={data?.description || meta?.description}
          />
        </Helmet>
        <Row className="mb-5 mt-3 pt-md-3">
          <Col lg="11">
            <h1 className="display-4 mb-4">{data?.title}</h1>{" "}
            <hr className="t_border my-4 ml-0 text-left" />
          </Col>
        </Row>
        <Row className="mb-5 mt-3 pt-md-3 pb-20">
          <Col lg="8">{data?.article}</Col>
        </Row>
      </Container>
    </HelmetProvider>
  );
};
